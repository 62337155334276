import axios from 'axios';
import url from '../config/url';
import HttpService from './HttpService';

export default class AuditCountService {
	constructor() {
		this.http = new HttpService();
		this.url = `${url.backendEndpoints.searchUrl}`;
		this.auditCountUrl = `${url.backendEndpoints.auditCount}`;
		this.uploadAuditCount = `${url.backendEndpoints.uploadAuditCount}`;
		this.scheduledCount = `${url.backendEndpoints.scheduledCount}`;
	}

	async searchAuditCountInstanceFactory(
		id,
		offset,
		limit,
		year = new Date().getFullYear(),
		searchQuery = '',
	) {
		return this.http.get({
			url: `${
				this.url
			}/erp-auditCount?countType=auditCount&scheduleCountid=${id.trim()}&year=${year}&searchField=${searchQuery.trim()}`,
			queryParams: { offset, limit },
		});
	}

	async signatureDownloadFactory(id) {
		return axios.get(`${this.scheduledCount}/${id}/signatureReport`, {
			responseType: 'blob',
		});
	}

	getAuditCount(endpoint) {
		return this.http.get({ url: endpoint });
	}

	async getAuditCountDetails(id) {
		const { data } = await this.http.get({
			url: `${this.auditCountUrl}/${id.trim()}/details`,
		});
		return data && data.createdDateTime;
	}

	async uploadAuditFactory(request, id) {
		const { data } = await this.http.post({
			url: `${this.uploadAuditCount}/${id.trim()}/actualresults/file`,
			data: request,
		});
		return data;
	}

	async uploadAuditFactoryStatus(id) {
		const { data } = await this.http.get({
			url: `${this.uploadAuditCount}/${id.trim()}/actualresults/file/status`,
		});
		return data;
	}
}
