import url from '../config/url';
import {
	DEFAULT_LIMIT_START,
	DEFAULT_OFFSET_START,
	DEFAULT_SORT_KEY,
	DEFAULT_SORT_ORDER,
	DEFAULT_VISUALIZATION_MODE,
} from '../constants';

import HttpService from './HttpService';

export default class VarianceAnalysisService {
	constructor() {
		this.http = new HttpService();
		this.url = `${url.backendEndpoints.varianceAnalysis}`;
	}

	/* getVarianceAnalysisFactory */
	async getVarianceAnalysisFactory(id, varianceType) {
		const { data } = await this.http.get({
			url: `${this.url}/${id}`,
			queryParams: { varianceType },
		});
		return data;
	}

	async searchCurrentVarianceAnalysisService({
		countInstanceId,
		searchQuery = '',
		offset = DEFAULT_OFFSET_START,
		limit = DEFAULT_LIMIT_START,
		sortBy = DEFAULT_SORT_KEY,
		sortKey = DEFAULT_SORT_ORDER,
		visualizationMode = DEFAULT_VISUALIZATION_MODE,
		filter = null,
		advFilterParams = null,
		year = new Date().getFullYear(),
	}) {
		const reasonCodeFilteredQuery = filter?.reasonCode?.join(',') ?? '';
		const subFranchiseFilteredQuery = filter?.subFranchise?.join(',') ?? '';
		const franchiseFilteredQuery = filter?.franchise?.join(',') ?? '';

		return this.http.get({
			url: `${
				this.url
			}/${countInstanceId}?varianceType=current&searchField=${searchQuery.trim()}&sortColumn=${sortBy}&sortKey=${sortKey}&visualizationBy=${visualizationMode}&advancedFilter=${advFilterParams}&reasonCode=${reasonCodeFilteredQuery}&franchise=${franchiseFilteredQuery}&subFranchise=${subFranchiseFilteredQuery}&year=${year}`,
			queryParams: { offset, limit },
		});
	}

	async getFranchiseSubFranchiseRcodeFactory(id, erpType) {
		return this.http.get({
			url: `${
				url.backendEndpoints.erpUrl
			}/erps/${erpType.toLowerCase()}/variance/${id}/columnFilterList`,
		});
	}

	// getVarianceAnalysisService
	async getVarianceAnalysisService(id) {
		const { data } = await this.http.get({
			url: `${this.url}/scheduledCount/${id}/varianceSummary`,
		});
		return data;
	}

	/* getVAUrl */
	getVarianceAnalysisUrl(endpoint) {
		return this.http.get({ url: endpoint });
	}

	async uploadVarianceFactory(filename, username, id) {
		const { data } = await this.http.get({
			url: `${this.url}/${id}/csvUpdate?email=${username}&filename=${filename}`,
		});
		return data;
	}

	async uploadVarianceNonValidatedFactory(filename, username, id) {
		const { data } = await this.http.post({
			url: `${this.url}/${id}/csvUpload?email=${username}&filename=${filename}`,
		});
		return data;
	}

	async uploadVarianceData(request) {
		const { data } = await this.http.post({
			url: `${url.backendEndpoints.s3Bucket}`,
			data: request,
		});
		return data;
	}

	async getUploadStatus(scheduleCountId) {
		const { data } = await this.http.get({
			url: `${this.url}/${scheduleCountId}/csvUpdate/status`,
		});
		return data;
	}
}
