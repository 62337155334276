/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { CloseOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ReactComponent as SelectIcon } from '../../assets/icons/export-down-icon.svg';
import {
	userManagementPermissions,
	userRoleNames,
	userRoles,
	userRolesUI,
} from '../../constants';
import { clusers } from '../../constants/country';
import { selectCurrentCountryName } from '../../redux/CountryList/CountryList.selector';
import {
	sendPasswordResetLinkAsync,
	showUserFromModal,
	submitUserStartAsync,
	submitUserSuccess,
} from '../../redux/User/User.actions';
import {
	selectIsPasswordResetSending,
	selectIsUserSubmitting,
	selectLoggedInUser,
	selectLoggedInUserRoles,
	selectSelectedUserToEdit,
	selectSendPasswordResetError,
	selectSendPasswordResetSuccess,
	selectUserPerms,
	selectUserSubmitError,
	selectUserSubmitSuccess,
} from '../../redux/User/User.selector';
import { selectTranslations } from '../../redux/i18n/i18nSlice';
import formatPhoneNumber, { mapUserRole } from '../../utils/UserRole.utils';
import { ErrorModal } from '../../utils/errorMessage';

let countryModalInstance;

/* select option */
const { Option } = Select;
/* User Create and Edit Modal */
function UserFromModal({
	countryCode,
	userPerms,
	submitUser,
	user,
	showModal,
	isSubmitting,
	loggedInUserRoles,
	sendPasswordResetLink,
	isSendingReset,
	loggedInUser,
}) {
	/* use Selector */
	const t = useSelector(selectTranslations);
	const countriesList = useSelector(
		state => state.getCountriesList.countryList,
	);

	/* init form */
	const [form] = Form.useForm();

	const [inputValue, setInputValue] = useState(user ? user.phoneNumber : '');

	/* Validate clusters */
	const invalidClusters = values =>
		clusers[0].filter(n => values.indexOf(n) !== -1).length > 0 &&
		clusers[1].filter(n => values.indexOf(n) !== -1).length > 0;
	/* Form on Finish */
	const onFinish = async values => {
		const newUser = {
			...values,
			email: values.email.toLowerCase(),
			status: !values.status ? 'active' : values.status,
			country: values.country,
		};
		/* submit with user and user id */
		// eslint-disable-next-line no-unused-expressions
		user
			? await submitUser(
					newUser,
					user.id,
					loggedInUser.id,
					countryCode.map(country => country.countryName).join(' '),
			  )
			: await submitUser(
					newUser,
					null,
					loggedInUser.id,
					countryCode.map(country => country.countryName).join(' '),
			  );
	};

	const handleInput = e => {
		setInputValue(formatPhoneNumber(e.target.value));
	};

	const onResetHandler = () => {
		const payload = {
			username: user.email,
		};
		if (user.roles.includes(userRoles.OPERATOR)) sendPasswordResetLink(payload);
		else ErrorModal('Password link is only for Mobile app users');
	};

	useEffect(() => {
		form.setFieldsValue({
			phoneNumber: inputValue,
		});
	}, [inputValue, form]);
	const checkRolePrivilege = value => {
		if (loggedInUserRoles.includes(userRoles.SYSTEM_ADMIN)) {
			return false;
		}
		if (
			loggedInUserRoles.includes(userRoles.CONSIGNMENT_ANALYST) &&
			!loggedInUserRoles.includes(userRoles.SYSTEM_ADMIN) &&
			!loggedInUserRoles.includes(userRoles.CONSIGNMENT_LEAD) &&
			value === userRolesUI.APPROVER
		) {
			return true;
		}
		if (
			value === userRolesUI.SYSTEM_ADMIN ||
			(value === userRolesUI.CONSIGNMENT_LEAD &&
				!userPerms.includes(userManagementPermissions.CONSIGNMENT_LEAD_CRUD))
		)
			return true;
		return !!(
			value === userRolesUI.SYSTEM_ADMIN ||
			(value === userRolesUI.CONSIGNMENT_ANALYST &&
				!userPerms.includes(userManagementPermissions.CONSIGNMENT_ANALYST_CRUD))
		);
	};
	/* user role list checkbox */
	const RoleCheckBox = (
		<Checkbox.Group className="grid grid-cols-2 gap-1">
			{Object.entries(userRolesUI).map(([key, value]) => (
				<div className="text-jnj_black text-base font-normal pb-4">
					<Checkbox
						disabled={checkRolePrivilege(value)}
						key={key}
						value={mapUserRole(key)}>
						{value}
					</Checkbox>
				</div>
			))}
		</Checkbox.Group>
	);
	const showCountryError = () => {
		if (countryModalInstance) countryModalInstance.destroy();
		countryModalInstance = Modal.error({
			title: 'Invalid country selection!',
			content:
				"You have selected countries belonging to different ERP, your selection won't be saved. Please select countries with the same ERP.",
			okCancel: false,
		});
	};
	/* Render */
	return (
		<Modal
			maskClosable={false}
			open
			closeIcon={
				<div className="text-lg p-4">
					<CloseOutlined />
				</div>
			}
			closable={false}
			style={{ width: '538px' }}
			footer={null}
			title={null}>
			<div>
				<Form
					initialValues={
						/* on Edit mode */
						user
							? {
									/* copy first name */
									firstName: user.firstName,
									/* copy last name */
									lastName: user.lastName,
									/* copy phone number */
									phoneNumber: user.phoneNumber,
									/* copy role */
									userRole: user.userRole,
									/* assign country */
									country: user.country,
									/* status */
									status: user.status,
									/* email */
									email: user.email,
									/* role */
									roles: user.roles,
							  }
							: null
					}
					onFinish={onFinish}
					form={form}>
					<div className="p-10">
						<div className="text-jnj_black text-lg font-black pb-4">
							<div>{user ? t.UpdateUser : t.CreateUser}</div>
						</div>
						<div className="border-t-2 pb-4" />
						<div className="text-jnj_black text-base font-bold grid grid-cols-2 gap-1 justify-between">
							<div>
								<div>
									{' '}
									<Form.Item
										name="firstName"
										rules={[
											{
												required: true,
												message: 'Please input user First Name!',
											},
										]}>
										<Input placeholder="First Name" />
									</Form.Item>
								</div>
							</div>
							<div>
								<div>
									{' '}
									<Form.Item
										name="lastName"
										rules={[
											{
												required: true,
												message: 'Please input user Last Name!',
											},
										]}>
										<Input placeholder="Last Name" />
									</Form.Item>
								</div>
							</div>
							<div>
								<div>
									{' '}
									<Form.Item
										name="email"
										rules={[
											{
												transform: input => input.trim(),
												required: true,
												message: 'Please input email address!',
												type: 'email',
											},
										]}>
										<Input placeholder="Email address" />
									</Form.Item>
								</div>
							</div>
							<div>
								<div>
									<Form.Item
										name="country"
										rules={[
											{
												required: true,
												message: 'Please input user country!',
											},
											() => ({
												validator(_, value) {
													if (invalidClusters(value)) {
														showCountryError();
														return Promise.reject(new Error(''));
													}
													return Promise.resolve();
												},
											}),
										]}>
										<Select
											value={
												loggedInUserRoles &&
												loggedInUserRoles.includes(userRoles.SYSTEM_ADMIN)
													? null
													: countryCode?.countryName
											}
											placeholder="Country"
											style={{ width: 200 }}
											suffixIcon={<SelectIcon />}
											mode="multiple"
											size="large"
											maxTagCount={1}>
											{loggedInUserRoles &&
											loggedInUserRoles.includes(userRoles.SYSTEM_ADMIN)
												? countriesList &&
												  countriesList
														?.filter(item => item.countryName !== 'ALL')
														?.map(list => (
															<Option
																key={list?.countryName}
																value={list?.countryName}>
																{list?.countryCode}
															</Option>
														))
												: countryCode &&
												  countryCode.map(({ countryName }) => (
														<Option value={countryName}>{countryName}</Option>
												  ))}
										</Select>
									</Form.Item>
								</div>
							</div>
							<div>
								<div>
									<Form.Item
										name="phoneNumber"
										rules={[
											{
												required: true,
												message: 'Please input user phone number!',
											},
										]}>
										<Input
											type="text"
											placeholder="Phone Number"
											onChange={e => handleInput(e)}
											addonBefore="+"
										/>
									</Form.Item>
								</div>
							</div>
							<div>
								<div>
									{' '}
									<Form.Item name="status">
										<Select
											defaultValue="active"
											placeholder="Status"
											suffixIcon={<SelectIcon />}>
											<Option value="active">Active</Option>
											<Option value="inactive">Inactive</Option>
										</Select>
									</Form.Item>
								</div>
							</div>
						</div>
						<div className="border-t-2 pb-4" />
						<div className="text-jnj_black text-lg font-black pb-4">
							<div>Roles</div>
						</div>
						<Form.Item
							name="roles"
							rules={[
								{
									required: true,
									message: 'Please input user Role!',
								},
								() => ({
									validator(_, value) {
										if (value.includes(userRoles.SYSTEM_ADMIN)) {
											for (
												let roleIndex = 0;
												roleIndex < value.length;
												roleIndex += 1
											) {
												if (
													[
														userRoles.APPROVER,
														userRoles.CONSIGNMENT_ANALYST,
														userRoles.CONSIGNMENT_LEAD,
													].includes(value[roleIndex])
												) {
													return Promise.reject(
														new Error(
															`${
																userRoleNames[value[roleIndex]]
															} role should not be included for Consignment Regional Lead`,
														),
													);
												}
											}
										}
										return Promise.resolve();
									},
								}),
							]}>
							{RoleCheckBox}
						</Form.Item>
						<div className="border-t-2 pb-4" />
						{user ? (
							<>
								<div className="text-jnj_red text-center pb-4">
									<Button
										type="text"
										className="text-jnj_red"
										onClick={onResetHandler}
										loading={isSendingReset}>
										Send Password Reset Link to User
									</Button>
								</div>
								<div className="border-t-2 pb-4" />
							</>
						) : (
							<div />
						)}
						<div className="flex justify-between">
							<div>
								<Button onClick={showModal} style={{ width: '220px' }}>
									{t.Cancel}
								</Button>
							</div>
							<div>
								<Button
									loading={isSubmitting}
									style={{ width: '220px' }}
									type="primary"
									htmlType="submit">
									{user ? `Update User` : `Create User`}
								</Button>
							</div>
						</div>
					</div>
				</Form>
			</div>
		</Modal>
	);
}
/* mapStateToProps */
const mapStateToProps = createStructuredSelector({
	/* selectors */
	loggedInUserRoles: selectLoggedInUserRoles,
	loggedInUser: selectLoggedInUser,
	userPerms: selectUserPerms,
	successMessage: selectUserSubmitSuccess,
	errorMessage: selectUserSubmitError,
	isSubmitting: selectIsUserSubmitting,
	user: selectSelectedUserToEdit,
	isSendingReset: selectIsPasswordResetSending,
	errorSendReset: selectSendPasswordResetError,
	successSendReset: selectSendPasswordResetSuccess,
	countryCode: selectCurrentCountryName,
});
/* mapDispatchToProps */
const mapDispatchToProps = dispatch => ({
	/* show Modal */
	showModal: () => dispatch(showUserFromModal()),
	/* submit user */
	submitUser: (data, id, loginId, country) =>
		dispatch(submitUserStartAsync(data, id, loginId, country)),
	/* password reset link */
	sendPasswordResetLink: payload =>
		dispatch(sendPasswordResetLinkAsync(payload)),
	setLoading: data => dispatch(submitUserSuccess(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(UserFromModal);
