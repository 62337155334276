import { Button, Modal, Table } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectConfigList } from '../../../redux/Configuration/Config.selector';
import {
	initVarianceAnalysisWorkflowStatusStartAsync,
	showSummaryModal,
} from '../../../redux/VarianceAnalysis/VarianceAnalysis.action';
import {
	selectFranchiseSubFranchiseReasonCode,
	selectShowSummaryModal,
	selectVarianceAnalysisSummary,
	selectVarianceAnalysisSummaryLoaded,
	selectWorkflowInitFetching,
	selectWorkflowStatus,
} from '../../../redux/VarianceAnalysis/VarianceAnalysis.selectors';
import { isDisableApprovalButton } from '../../../utils/VarianceAnalysis.utils';
import { ErrorModal } from '../../../utils/errorMessage';
import ColoredValue from '../VarianceAnalysisShared/VarianceAnalysisShared.component';
/* modal header */
function ModalHeader() {
	return (
		<p className="header mx-2 text-lg font-black border-b pb-6">Summary</p>
	);
}
/* item component */
export function Item({ label, value, colored }) {
	return (
		<div className="w-full flex border-t last:border-red">
			<p className="w-1/2 font-normal  mx-2 border-r border-jnj_border h-10 flex items-center">
				{label}
			</p>
			<p className="mx-2 font-black text-center w-1/2 h-10 flex items-center justify-center">
				{colored ? <ColoredValue value={value} /> : value}
			</p>
		</div>
	);
}
/* footer component */
export function ModalFooter({
	handleCancel,
	workflowStatus,
	requestApproval,
	summaryData,
	workflowIsFetching,
	franchiseSubFranchiseReasonCode,
	configurationsList,
}) {
	const requestApprovalHandler = () => {
		if (
			configurationsList?.reconConfig?.approversL1?.length &&
			configurationsList?.reconConfig?.approversL2?.length &&
			configurationsList?.reconConfig?.approversL3?.length
		)
			return requestApproval(summaryData?.scheduled_count_id);
		return ErrorModal('Cannot send Approval - configuration missing');
	};
	return (
		<>
			<div className="flex">
				{/* cancel button */}
				<Button
					className="w-1/2 h-44px"
					htmlType="button"
					onClick={handleCancel}
					style={{ color: '#888B8D' }}>
					Cancel
				</Button>
				{/* send button */}
				<Button
					loading={workflowIsFetching}
					onClick={requestApprovalHandler}
					disabled={isDisableApprovalButton(
						franchiseSubFranchiseReasonCode?.isReasonCodes,
						workflowStatus?.approvalStatus,
					)}
					type="primary"
					className="w-1/2 h-44px">
					Request Approval
				</Button>
			</div>

			<div className="text-jnj_red flex justify-center">
				{isDisableApprovalButton(
					franchiseSubFranchiseReasonCode?.isReasonCodes,
					workflowStatus?.approvalStatus,
				)
					? '*Reason codes need to be filled in for all lines in Variance Analysis'
					: ''}
			</div>
		</>
	);
}
/* variance analysis summary component */
function VarianceAnalysisSummary({
	showModal = true,
	setShowSummery,
	summaryData,
	hideModal,
	workflowStatus,
	requestApproval,
	workflowIsFetching,
	franchiseSubFranchiseReasonCode,
	configurationsList,
}) {
	/* cancel handler */
	const handleCancel = () => {
		setShowSummery(false);
	};

	const columns = [
		{
			title: <div className=" font-bold">Reason Code</div>,
			dataIndex: 'reason_code',
			key: 'reason_code',
			render: text => <p>{text}</p>,
		},
		{
			title: <div className=" font-bold">Total</div>,
			dataIndex: 'tot_reason_code',
			key: 'tot_reason_code',
		},
	];

	/* modal */
	return (
		<Modal
			width="874px"
			closable={false}
			footer={null}
			open={showModal}
			onCancel={handleCancel}>
			<div className="summary-body flex flex-col p-10 gap-6">
				{/* modal header */}
				<ModalHeader />
				<div className="count-id mx-2 pb-4 border-b">
					{/* count id */}
					<span className=" text-jnj_black font-black text-base ">
						Count ID:
					</span>
					<span className="count-id-value font-normal">
						{' '}
						{summaryData?.scheduled_count_id}
					</span>
				</div>
				{/* column  */}
				<div className="flex flex-col border-jnj_border border-l border-t border-r rounded">
					<div
						className="flex flex-row border-b"
						style={{ background: '#f4f4f4' }}>
						<p className="w-3/4 font-black mx-2 border-r border-jnj_border flex items-center ">
							CAC Name
						</p>
						<p className=" font-black mx-2 border-r border-jnj_border w-1/4 flex items-center ">
							Ship To
						</p>
						<p className="font-black h-10 mx-2 text-center w-1/4 flex items-center">
							Sub Franchise
						</p>
					</div>
					<div className="flex flex-row border-b">
						<p className="w-3/4 mx-2 border-r border-jnj_border items-center  ">
							{summaryData?.scheduled_count_data?.cac_customer_name.map(
								item => (
									<p className="pb-4 pt-1 ">{item}</p>
								),
							)}
						</p>
						<p className=" mx-2 border-r border-jnj_border w-1/4 items-center ">
							{summaryData?.scheduled_count_data?.ship_to?.map(item => (
								<p className="pb-4 pt-1 ">{item}</p>
							))}
						</p>
						<p className=" h-10 mx-2  w-1/4  items-center">
							{summaryData?.scheduled_count_data?.franchise_code.map(item => (
								<p className="pb-4 pt-1 ">{item}</p>
							))}
						</p>
					</div>
					{/* summary content */}
				</div>

				<div className="flex flex-row gap-6">
					<div
						className="flex flex-col border-jnj_border border-l border-r border-b rounded last:border-red"
						style={{ width: '397px' }}>
						<Item
							label="Expected Value"
							value={summaryData?.expected_value}
							colored
						/>
						<Item
							label="Count Value"
							value={summaryData?.count_value}
							colored
						/>
						<Item
							label="Final Reconciled Value"
							value={summaryData?.reconciled_value}
							colored
						/>
					</div>
					<div
						className="flex flex-col border-jnj_border border-l border-r border-b rounded w-96 h-full"
						style={{ width: '373px' }}>
						<Item label="Missing" value={summaryData?.items_missing} colored />
						<Item
							label="Extra Found"
							value={summaryData?.items_extra}
							colored
						/>
						<Item
							label="Overall Variance"
							value={summaryData?.overall_variance}
							colored
						/>
					</div>
				</div>
				<div className="flex flex-col border-jnj_border border-l border-t border-r rounded">
					<Table
						scroll={{ y: 150 }}
						pagination={false}
						columns={columns}
						dataSource={summaryData?.reason_codes.map(e => ({
							reason_code: e.reason_code,
							tot_reason_code: <ColoredValue value={e.tot_reason_code} />,
						}))}
					/>
				</div>
				<div className="border-t" />
				{/* Modal footer comp. */}
				<ModalFooter
					handleCancel={hideModal}
					workflowStatus={workflowStatus}
					summaryData={summaryData}
					requestApproval={requestApproval}
					workflowIsFetching={workflowIsFetching}
					franchiseSubFranchiseReasonCode={franchiseSubFranchiseReasonCode}
					configurationsList={configurationsList}
				/>
			</div>
		</Modal>
	);
}
const mapStateToProps = createStructuredSelector({
	summaryData: selectVarianceAnalysisSummary,
	summaryLoading: selectVarianceAnalysisSummaryLoaded,
	showSummaryModal: selectShowSummaryModal,
	workflowStatus: selectWorkflowStatus,
	workflowIsFetching: selectWorkflowInitFetching,
	configurationsList: selectConfigList,
	franchiseSubFranchiseReasonCode: selectFranchiseSubFranchiseReasonCode,
});
const mapDispatchToProps = dispatch => ({
	hideModal: () => dispatch(showSummaryModal()),
	requestApproval: scheduledCountId =>
		dispatch(
			initVarianceAnalysisWorkflowStatusStartAsync({
				scheduledCountId,
			}),
		),
});
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(VarianceAnalysisSummary);
