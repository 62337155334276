/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button, DatePicker, Form, Input, Modal } from 'antd';
import moment from 'moment';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ReactComponent as CloseOutlined } from '../../assets/icons/closeOutlined.svg';
import { databaseDateFormat } from '../../constants/country';
import { showEditCountInstanceModal } from '../../redux/CountInstanceDetails/CountInstanceDetails.actions';
import { selectShowSendNotificationModal } from '../../redux/CountInstanceDetails/CountInstanceDetails.selectors';
import { updateCountInstanceStartAsync } from '../../redux/CountInstanceDetails/CountInstanceSingle/CountInstanceSingle.actions';
import {
	selectCountInstance,
	selectCountInstanceIsUpdating,
} from '../../redux/CountInstanceDetails/CountInstanceSingle/CountInstanceSingle.selector';
import { selectUserCountry } from '../../redux/User/User.selector';
import { selectTranslations } from '../../redux/i18n/i18nSlice';
import { getCountryDateFormat } from '../../utils/GetCountryFormats.utils';

function EditCountInstanceModal({
	countryCode,
	isUpdating,
	updateCountInstance,
	countInstance,
	showModal,
	title,
}) {
	// selector for localization implementation
	const t = useSelector(selectTranslations);
	const [form] = Form.useForm();

	const dateFormat = getCountryDateFormat(countryCode);

	// Update count instance values s
	const onFinish = values => {
		updateCountInstance(
			{
				...values,
				expectedStartDate: values.expectedStartDate
					? moment(values.expectedStartDate, dateFormat).format(
							databaseDateFormat,
					  )
					: null,
				expectedEndDate: values.expectedEndDate
					? moment(values.expectedEndDate, dateFormat).format(
							databaseDateFormat,
					  )
					: null,
			},
			countInstance.scheduledCountID,
		);
	};
	return (
		<Modal
			maskClosable={false}
			open
			closeIcon={
				<div>
					<CloseOutlined />
				</div>
			}
			closable={false}
			footer={null}
			title={null}>
			<div className="p-10">
				<div className="text-jnj_black text-lg font-black pb-4">{title}</div>
				<div className="border-t-2 pb-4" />
				<div>
					<Form
						labelCol={{
							span: 8,
						}}
						initialValues={{
							expectedStartDate:
								countInstance && countInstance.expectedStartDate
									? moment(countInstance.expectedStartDate)
									: null,
							expectedEndDate:
								countInstance && countInstance.expectedEndDate
									? moment(countInstance.expectedEndDate)
									: null,
							operator: countInstance ? countInstance.operator : '',
							approver: countInstance ? countInstance.approver : '',
						}}
						onFinish={onFinish}
						form={form}>
						<div>
							<div className="pb-2 font-bold text-base">{t.CountInstance}:</div>
							<Form.Item>
								{countInstance ? (
									<Input
										className="font-normal text-base"
										placeholder={countInstance.scheduledCountID}
										disabled
									/>
								) : null}
							</Form.Item>
						</div>
						<div className="flex justify-between">
							<div>
								<div className="flex">
									<div className="text-base font-bold">
										{t.ExpectedCountStartDate}:
									</div>
								</div>

								<Form.Item
									name="expectedStartDate"
									rules={[
										({ getFieldValue }) => ({
											validator(_, value) {
												if (
													!value ||
													!moment(getFieldValue('expectedEndDate')).isValid()
												) {
													return Promise.resolve();
												}
												if (
													!value ||
													moment(getFieldValue('expectedEndDate')) >
														moment(value)
												) {
													return Promise.resolve();
												}
												return Promise.reject(
													new Error('Start date is not valid!'),
												);
											},
										}),
									]}>
									<DatePicker format={dateFormat} />
								</Form.Item>
							</div>
							<div>
								<div className="flex">
									<div className="text-base font-bold">
										{t.ExpectedCountEndDate}:
									</div>
								</div>
								<Form.Item
									name="expectedEndDate"
									rules={[
										({ getFieldValue }) => ({
											validator(_, value) {
												if (
													!value ||
													moment(getFieldValue('expectedStartDate')) <
														moment(value)
												) {
													return Promise.resolve();
												}
												return Promise.reject(
													new Error('End date is not valid!'),
												);
											},
										}),
									]}>
									<DatePicker format={dateFormat} />
								</Form.Item>
							</div>
						</div>
						<div>
							<div className="pb-2 font-bold text-base">{t.Operator}:</div>
							<Input
								disabled
								value={countInstance ? countInstance.operator : ''}
								placeholder="No Operator Assigned"
							/>
						</div>
						<div>
							<div className="pb-2 font-bold text-base">
								{t.AssignedApprover}:
							</div>
							<Input
								disabled
								value={countInstance ? countInstance.approver : ''}
								placeholder="No Approver Assigned"
							/>
						</div>
						<div className="flex-1 flex-wrap">
							<div className="flex content-center p-1">
								<Button
									onClick={showModal}
									className="font-black text-lg"
									htmlType="button"
									style={{ width: '220px' }}>
									{t.Cancel}
								</Button>
								<Button
									type="primary"
									className="font-bold text-lg"
									loading={isUpdating}
									htmlType="submit"
									style={{ width: '220px' }}>
									{t.Submit}
								</Button>
							</div>
						</div>
					</Form>
				</div>
			</div>
		</Modal>
	);
}
// map state to props
const mapStateToProps = createStructuredSelector({
	countryCode: selectUserCountry,
	isModalVisible: selectShowSendNotificationModal,
	countInstance: selectCountInstance,
	isUpdating: selectCountInstanceIsUpdating,
});
// map dispatch to props
const mapDispatchToProps = dispatch => ({
	showModal: () => dispatch(showEditCountInstanceModal()),
	updateCountInstance: (data, countId) =>
		dispatch(updateCountInstanceStartAsync(data, countId)),
});
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(EditCountInstanceModal);
